<template>
	<div class="policy_wrap">
		<h2 class="policy">스토리버드 서비스 이용약관</h2>
		<!-- <div class="list_wrap">
			<ul>
				<li><a href="#article01">제1조 목적</a></li>
				<li><a href="#article02">제2조 정의</a></li>
				<li><a href="#article03">제3조 회사정보 등의 제공</a></li>
				<li><a href="#article04">제4조 약관의 효력 및 변경</a></li>
				<li><a href="#article05">제5조 약관 외 준칙</a></li>
				<li><a href="#article06">제6조 개인정보의 보호 및 사용</a></li>
				<li><a href="#article07">제7조 개인정보의 위탁</a></li>
				<li><a href="#article08">제8조 서비스의 이용</a></li>
				<li><a href="#article09">제9조 회원정보의 변경</a></li>
				<li><a href="#article10">제10조 회원에 대한 통지</a></li>
				<li><a href="#article11">제11조 서비스의 변경 및 중단</a></li>
				<li><a href="#article12">제12조 서비스의 이용제한</a></li>
				<li><a href="#article13">제13조 유료서비스 이용방법 및 결제 등</a></li>
				<li><a href="#article14">제14조 사용기간 등</a></li>
				<li><a href="#article15">제15조 정보의 제공 및 광고의 게재</a></li>
			</ul>
			<ul>
				<li><a href="#article16">제16조 콘텐츠의 저작권</a></li>
				<li><a href="#article17">제17조 캐시 등의 지급</a></li>
				<li><a href="#article18">제18조 서비스 이용의 기술적 요구 사항</a></li>
				<li><a href="#article19">제19조 "스토리버드의" 의무</a></li>
				<li><a href="#article20">제20조 회원의 의무</a></li>
				<li><a href="#article21">제21조 청약철회, 계약해지 등</a></li>
				<li><a href="#article22">제22조 계약해지 또는 해제의 환불</a></li>
				<li><a href="#article23">제23조 과오금의 환불</a></li>
				<li><a href="#article24">제24조 손해배상</a></li>
				<li><a href="#article25">제25조 면책</a></li>
				<li><a href="#article26">제26조 양도금지</a></li>
				<li><a href="#article27">제27조 관할법원</a></li>
				<li><a href="#article28">제28조 약관 외 준칙 및 관련 법령과의 관계</a></li>
				<li><a href="#article29">제29조 면책 사항1</a></li>
				<li><a href="#article30">제30조 관할 법원3</a></li>
			</ul>
		</div> -->
		<div class="list_wrap">
			<ul>
				<li><a href="#link01">제1조 목적</a></li>
				<li><a href="#link02">제2조 약관의 효력 및 변경</a></li>
				<li><a href="#link03">제3조 용어의 정의</a></li>
				<li><a href="#link04">제4조 서비스의 종류</a></li>
				<li><a href="#link05">제5조 서비스 이용계약의 성립</a></li>
				<li><a href="#link06">제6조 유료 서비스 이용계약의 성립 및 승낙의 제한 </a></li>
				<li><a href="#link07">제7조 프로그램 설치 및 데이터 수집</a></li>
				<li><a href="#link08">제8조 서비스 이용의 기술적 요구 사항 </a></li>
				<li><a href="#link09">제9조 유료 서비스와 유료회원 </a></li>
				<li><a href="#link10">제10조 결제 수단 </a></li>
				<li><a href="#link11">제11조 서비스 지역 </a></li>
				<li><a href="#link12">제12조 개인정보의 변경</a></li>
				<li><a href="#link13">제13조 개인정보의 보호</a></li>
				<li><a href="#link14">제14조 아이디 및 비밀번호의 관리</a></li>
				<li><a href="#link15">제15조 이용계약의 종료</a></li>
			</ul>
			<ul>
				<li><a href="#link16">제16조 상품의 구매 결제 및 이용</a></li>
				<li><a href="#link17">제17조 상품의 구매 방법</a></li>
				<li><a href="#link18">제18조 상품 등급과 유료상품 내용 등의 게시</a></li>
				<li><a href="#link19">제19조 청약철회, 환불</a></li>
				<li><a href="#link20">제20조 미성년자 이용계약에 관한 특칙</a></li>
				<li><a href="#link21">제21조 서비스 기간과 중지 등</a></li>
				<li><a href="#link22">제22조 서비스 이용료</a></li>
				<li><a href="#link23">제23조 정보 및 광고의 제공</a></li>
				<li><a href="#link24">제24조 회원 관리</a></li>
				<li><a href="#link25">제25조 게시물 또는 내용물의 삭제 </a></li>
				<li><a href="#link26">제26조 게시물의 저작권</a></li>
				<li><a href="#link27">제27조 금지행위</a></li>
				<li><a href="#link28">제28조 약관 외 준칙 및 관련 법령과의 관계</a></li>
				<li><a href="#link29">제29조 면책 사항</a></li>
				<li><a href="#link30">제30조 관할 법원</a></li>
			</ul>
		</div>
		<div class="policy_cnt">
			<div class="text_bundle_wrap">
				<div class="text_bundle">
					<h2>제1장 총칙</h2>
					<h3 id="link01">제1조 목적</h3>
					<p>
						본 약관은 주식회사 아이디어 콘서트(이하 ‘회사’라 합니다)가 운영하는 디지털 콘텐츠 서비스인
						스토리버드(STORYBIRD) 에서 제공하는 웹툰 및 기타 서비스(이하 ‘서비스’라 합니다)의 이용과
						관련하여 회사와 회원과의 권리, 의무 및 책임사항 등을 규정함을 목적으로 합니다.
					</p>
				</div>
				<div class="text_bundle">
					<h3 id="link02">제2조 약관의 효력 및 변경</h3>
					<ul>
						<li>
							① 본 약관은 서비스를 이용하고자 본 약관에 동의한 모든 회원에 대하여 그 효력을 발생합니다.
						</li>
						<li>
							② 본 약관의 내용은 스토리버드(STORYBIRD) 인터넷 홈페이지 (http://storybird.co.kr
							이하’홈페이지’라 합니다.), 스토리버드(STORYBIRD) 어플리케이션에 게재되고, 본 약관에 대하여
							회원이 동의함으로써 그 효력이 발생합니다.
						</li>
						<li>
							③ 회사는 약관의 규제에 관한 법률 전자상거래 등에서의 소비자보호에 관한 법률, 정보통신망
							이용촉진 및 정보보호에 관한 법률, 방문판매 등에 관한 법률 등 관련법령을 위반하지 않는 범위
							내에서 필요 시 약관을 변경할 수 있으며, 약관을 변경할 경우에는 적용일자 및 변경사유를
							명시하여 현행약관과 함께 서비스 초기화면에 그 적용일자 10일 이전부터 적용일자 이후 상당한
							기간 동안 공지합니다. 다만, 회원에게 불리하게 변경되거나 중요한 내용인 경우에는 30일
							이전부터 상기 방식으로 공지하며 이러한 공지 외에 이메일 발송, 로그인 시 동의창 등 전자적
							수단을 통해 별도로 통지합니다.
						</li>
						<li>
							④ 회사가 본 조 제3항에 따라 변경 약관을 공지 또는 통지하면서, 회원이 약관 변경 적용일까지
							거부의사를 표시하지 아니하는 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는
							통지하였음에도 회원이 명시적으로 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부의사를
							표시하지 아니하면 회원이 변경 약관에 동의한 것으로 간주합니다. 회원은 변경된 약관에 동의하지
							않을 경우 본 서비스 이용을 중단하고 이용계약을 해지할 수 있습니다.
						</li>
						<li>
							⑤ 본 약관에 동의하는 것은 회사가 운영하는 본 서비스의 홈페이지를 정기적으로 방문하여 약관의
							변경사항을 확인하는 것에 동의함을 의미합니다. 변경된 약관에 대한 정보를 알지 못하여 발생하는
							회원의 피해에 대하여 회사는 책임이 없습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link03">제3조 용어의 정의</h3>
					<ul>
						<li>
							① 본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다.
							<ul>
								<li>1. 회원 : 본 약관에 동의한 개인이나 법인 고객</li>
								<li>
									2. 아이디 : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는
									영문자와 숫자의 조합
								</li>
								<li>
									3. 비밀번호 : 회원의 정보보호 및 회원 확인 등을 위해 회원 자신이 설정한 문자와
									숫자의 조합
								</li>
								<li>4. 상품 : 서비스의 판매 또는 전송을 위하여 등록한 프로그램 또는 콘텐츠</li>
								<li>
									5. 게시물 : 서비스 내에 회원이 올린 영상, 글, 사진, 그림, 각종 파일과 링크, 각종
									답글 등의 정보의 총칭
								</li>
								<li>
									6. 유료회원 : 별도의 금액을 지불하고 멤버쉽 이용권 등을 결제하여 유료 서비스를
									이용하는 회원을 의미합니다.
								</li>
								<li>
									7. 유료 서비스 : 회사가 제공하는 서비스 중 회원이 회사에 일정 금액을 결제하거나,
									회원이 회사 또는 제3자와의 거래 내지 약정 조건을 수락하는 경우에 이용할 수 있는
									회사의 서비스를 의미합니다. 단, 회사 또는 제3자로부터 사이트에 연결(링크)된 유료
									콘텐츠 제공업체 및 회사와의 계약을 통하여 입점한 제휴 서비스는 회사의 유료 서비스
									이용행위로 보지 않으며, 본 약관을 적용하지 아니합니다.
								</li>
								<li>
									8. 결제 : 회원이 유료 서비스를 이용하기 위하여 이 약관 제9조 각호에서 정한 각종
									지불수단을 통하여 회사가 정한 일정 금액을 회사에 지불하는 것을 의미합니다.
								</li>
								<li>
									9. 콘텐츠 : 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제2조 제1항 제1호의
									규정에 의한 정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로
									표현된 자료 또는 정보입니다.
								</li>
								<li>
									10. 유료 서비스 이용계약: 스토리버드(STORYBIRD)의 유료 서비스를 이용하고자 하는
									회원의 결제 상품에 따라, 회사는 현재 제공하고자 하는 디지털 콘텐츠에 대해 최소 1개월
									단위의 접근 권한을 회원에게 부여하고 디지털 콘텐츠에 대한 정보 및 개인화된 추천,
									큐레이션 등을 제공하며, 회원은 유료서비스 해지 의사를 밝힐 시 이용권의 사용일 수를
									계산하여 남는 일수에 비례한 금액을 환불받을 수 있습니다.
								</li>
							</ul>
						</li>
						<li>
							② 본 조 제1항에서 정의된 용어 이외의 용어에 대해서는 관계 법령 및 서비스 별 이용기준에서
							정의하는 바에 따릅니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link04">제4조 서비스의 종류</h3>
					<p>회사가 제공하는 서비스는 다음과 같습니다.</p>
					<ul>
						<li>1. 소설 더빙본 등의 상품 제공 및 판매 서비스</li>
						<li>2. 소설 더빙본 등의 정보검색 서비스</li>
						<li>3. 광고 및 프로모션 서비스</li>
						<li>
							4. 기타서비스 : 상품에 대한 문의하기, 댓글, 별점, 추천 등 기타 정보제공 서비스, 회사가 직접
							또는 제휴사와 공동으로 제공하는 이벤트 서비스
						</li>
					</ul>
				</div>
			</div>
			<div class="text_bundle_wrap">
				<h2>제2장 서비스 이용계약 및 정보보호</h2>
				<div class="text_bundle">
					<h3 id="link05">제5조 서비스 이용계약의 성립</h3>
					<ul>
						<li>
							① 서비스 이용계약은 회원이 되고자 하는 자가 본 약관의 내용에 대하여 동의를 한 다음
							회원가입신청을 하고 회사는 이러한 신청에 대하여 승낙함으로써 체결됩니다.
						</li>
						<li>
							② 회사는 다음 각 호의 경우에 한하여 이용신청을 거절할 수 있습니다.
							<ul>
								<li>1. 가입신청자의 실명이 아니거나 타인의 명의를 이용한 경우</li>
								<li>2. 사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우</li>
								<li>
									3. 회원 자격을 상실한 자로서 회사가 정한 일정 가입보류 기간이 경과되지 아니한 자
								</li>
								<li>4. 만 14세 미만의 아동이 법정대리인의 동의를 받지 아니한 경우</li>
								<li>5. 기타 회사가 별도로 정한 이용신청 기준에 부합되지 아니한 자</li>
							</ul>
						</li>
						<li>
							③ 회사는 다음과 같은 사유가 있는 경우, 이용신청에 대한 승낙을 유보할 수 있습니다.
							<ul>
								<li>1. 회사에 의하여 이용계약이 해지된 날로부터 30일 이내에 재이용신청을 하는 경우</li>
								<li>
									2. 회사로부터 회원자격 정지 조치 등을 받은 회원이 그 조치기간 중에 이용계약을 임의
									해지하고 재이용신청을 하는 경우
								</li>
								<li>3. 설비에 여유가 없거나 기술상 지장이 있는 경우</li>
								<li>
									4. 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가
									합리적인 판단에 의하여 필요하다고 인정하는 경우
								</li>
							</ul>
						</li>
						<li>
							④ 본 조 제2항 또는 제3항에 따라 회사가 회원가입 신청의 승낙을 하지 아니하거나 이를 유보한
							경우, 회사는 이용신청자에게 승낙 유보의 사유, 승낙 가능시기 또는 승낙에 필요한 추가요청정보
							내지 자료 등 기타 승낙 유보와 관련된 사항을 해당 서비스 화면에 게시하거나 이메일을 통하여
							통지합니다.
						</li>
						<li>
							⑤ 회사는 본 서비스의 품질 향상 기타 필요한 경우, 회원을 등급별로 구분하여 이용시간,
							이용횟수, 서비스 메뉴 기타 필요한 사항을 세분하여 그 이용에 차등을 둘 수 있습니다.
						</li>
						<li>
							⑥ 회사는 회원에 대하여 게임산업진흥에 관한 법률, 청소년보호법 기타 관련법령에 따른 등급 및
							연령 준수를 위하여 이용제한이나 등급별 제한 기타 필요한 조치를 취할 수 있습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link06">제6조 유료 서비스 이용계약의 성립 및 승낙의 제한</h3>
					<p>
						1항. 회사가 운영하는 스토리버드(STORYBIRD) 유료 서비스 대한 이용계약은 이 약관에 동의한다는
						의사표시(동의함을 선택)와 함께 이용 신청을 하고 회사가 이를 승낙함으로써 성립합니다.
					</p>
					<p>
						2항. 회원은 유료 서비스 이용계약을 체결하기 전에 해당 유료 서비스에 대하여 이 약관에서 회사가
						명시, 고지하는 사항을 숙지하고, 착오 없이 정확히 거래할 수 있도록 하여야 합니다.
					</p>
					<p>
						3항. 회사와 회원간 유료 서비스 이용계약은 회사의 승낙이 있는 때(유료 서비스의 “구매/결제 완료
						등”의 표시가 회원에게 절차상 표시된 시점)에 성립합니다.
					</p>
					<p>
						4항. 회원은 유료 서비스 이용 신청 시 필요한 제반 사항을 기재할 경우 회원의 현재의 사실과
						일치하는 정보를 기재하여야 하며, 회원이 이용하고자 하는 유료 서비스의 결제 방법의 선택 및 선택한
						결제 방법에 필요한 결제 정보를 정확히 회사에 제공하여야 하며, 해당 사항이 변경될 경우 지체 없이
						회사가 정한 절차에 따라 변경사항을 고지, 반영하여야 합니다.
					</p>
					<p>
						5항. 회사는 회원 가입에 필요한 기재사항 이외에 회원의 서비스 이용에 필요한 최소한의 정보를
						수집할 수 있습니다. 이를 위해 회사가 문의한 사항에 대해 회원은 성실하게 고지합니다. 회사는 이
						경우 수집하는 회원의 정보를 스토리버드(STORYBIRD) 이용약관, 이 약관, 개인정보취급방침 및
						「정보통신망의 이용촉진 및 정보보호 등에 관한 법률」이 정한 바에 따라 이용, 관리합니다.
					</p>
				</div>
				<div class="text_bundle">
					<h3 id="link07">제7조 프로그램 설치 및 데이터 수집</h3>
					<p>
						1항. 회사는 유료 서비스를 제공함에 있어 필요한 경우 회원에게 어플리케이션 및 별도의 프로그램을
						설치 및 업데이트하도록 안내할 수 있으며, 회원은 원하지 않을 경우 그 설치 및 업데이트를 거부할 수
						있습니다. 다만, 회원이 어플리케이션 및 별도 프로그램의 설치 및 업데이트를 거부하는 경우 서비스
						이용이 제한될 수 있습니다.
					</p>
					<p>2항. 회사는 회원의 서비스 이용 단말기의 데이터를 수집하고 이용할 수 있습니다.</p>
					<p>
						3항. 회사는 제2항의 데이터를 서비스를 개선하거나 이용자의 사용환경에 적합한 서비스 또는 기술을
						제공하기 위한 목적으로만 사용하며 그 외의 다른 목적으로 사용하지 않습니다.
					</p>
				</div>
				<div class="text_bundle">
					<h3 id="link08">제8조 서비스 이용의 기술적 요구 사항</h3>
					<p>
						1항. 회사는 서비스를 공급하면서 다음 각 호의 사양이 충족되어야 정상적인 서비스 이용이 가능하며,
						회원이 아래의 필수 사양을 충족하지 못할 경우 서비스 이용이 제한될 수 있습니다.
					</p>
					<span class="tit">[서비스 이용 필수 사양]</span>
					<ul>
						<li>
							1) 컴퓨터(PC) 유료서비스는 아래 열거한 웹브라우저 이용이 가능한 Windows, macOS, Linux 등의
							OS에서 동작합니다.
							<div class="text_indent">
								- 지원 웹브라우저 : Chrome, Edge, Firefox (상기 브라우저의 최신 정식 버전에서 가장 잘
								동작합니다)
							</div>
						</li>
						<li>
							2) 모바일 단말기(스마트폰, 태블릿 PC) 유료서비스는 가장 최신의 정식 버전 OS에서 가장 잘
							동작하며, 최소 지원 OS 버전 정보는 다음과 같습니다.
							<div class="text_indent">
								- Android : 4.4 버전 이상<br />
								- Android TV : 5.0 버전 이상(일부 기기는 제조사 사정에 따라 지원이 제외될 수
								있습니다)<br />
								- iOS: iOS 11.0 버전 이상
							</div>
						</li>
						<li>
							3) 스마트 TV
							<div class="text_indent">
								- 2016년형을 포함한 그 이후 출시된 삼성 스마트 TV<br />
								- 2016년형을 포함한 그 이후 출시된 LG 스마트 TV
							</div>
						</li>
						<li>
							4) 기타
							<div class="text_indent">
								- 크롬캐스트(1/2/3세대, Ultra) 2항. 유료 서비스의 화질은 회원이 결제한 이용권, 사용하는
								디바이스, 인터넷 접속 속도, 인터넷 대역폭 등 다양한 요인의 영향을 받기 때문에 모든
								콘텐츠가 언제나 HD, Full HD, Ultra HD, HDR 등의 화질로 제공되는 것은 아닙니다.
							</div>
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link09">제9조 유료 서비스와 유료회원</h3>
					<p>
						1항. 회사가 유료회원의 이용신청을 승낙한 때(일부 서비스의 경우, 이용 신청 접수 후 사전 공지한
						지정된 일자)로부터 유료 서비스는 개시되며, 회사의 기술적 사유 등 기타 사정에 의하여 유료
						서비스를 개시할 수 없는 경우에는 제3조(약관의 효력/변경 등)의 방법에 따라 회원에게 사전
						공지합니다.
					</p>
					<p>
						2항. 회사는 회원의 유료 서비스 이용신청이 있게 되는 경우, 해당 회원에게 이용신청의 내용을
						통지합니다. 회사의 통지를 받은 회원은 의사표시의 불일치 등이 있는 경우 회사에 정정 또는 수정을
						요청하여야 하며, 회사는 회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는
						이 약관의 청약 철회 등에 관한 규정에 따릅니다.
					</p>
					<p>
						3항. 회사는 회원이 유료 서비스 이용시 서비스 체험을 위해 무료체험 기간이 포함된 유료서비스
						상품을 회원에게 제안할 수 있습니다. 이 경우 회사는 해당 상품이 무료체험 기간이 포함된 유료
						서비스임을 설명하고, 무료체험 기간 중에 회원은 언제든지 이용계약을 해지할 수 있고 이 경우 요금이
						청구되지 않는다는 사실을 회원이 충분히 인지할 수 있도록 설명하여야 합니다.
					</p>
					<p>
						4항. 회사는 다음 각 호와 같은 유료 서비스를 제공하며, 회사의 사정, 기타 제반 여건에 따라 서비스
						내용을 추가하거나 변경할 수 있습니다.
					</p>
					<ul>
						<li>
							1) 자동결제 여부에 따른 분류
							<div class="text_indent">
								- 정기결제형 이용권 : 유료회원이 미리 등록한 결제 수단을 통하여 월 단위로 이용요금이
								자동으로 결제되고 이용기간이 자동 갱신되는 서비스<br />
								- 기간만료형 이용권 : 이용 가능 기간이 만료되는 경우, 유료 서비스의 이용이 종료되는
								서비스
							</div>
						</li>
						<li>
							2) 판매방식에 따른 분류
							<div class="text_indent">
								- 직접판매 : 회사가 제공하는 유료 서비스를 회원에게 결제 수단을 통해 직접 제공하는
								방식을 말합니다.<br />
								- 대행판매 : 회사가 제공하는 유료 서비스를 제3자를 통해 판매하는 방식으로 해당
								판매상품의 이용방식은 제휴사가 제공하는 유료 서비스 안내에 따릅니다.
							</div>
						</li>
					</ul>
					<p>
						5항. 유료회원이 유/무선 네트워크를 통하여 서비스에 접속하거나 유/무선 네트워크가 연결된 상태의
						기기 내에 탑재된 어플리케이션을 통하여 제반 서비스를 이용하는 경우, 유료회원과 유료회원이 가입한
						해당 통신사간에 체결된 통신 요금제에 의한 별도의 데이터 통화료가 발생합니다. 이 경우 발생하는
						데이터 통화료는 유료회원과 해당 통신사간에 체결된 통신요금제에 따라 과금/청구/수납되므로, 데이터
						통화료에 대해서는 회사는 어떠한 책임도 지지 않습니다.
					</p>
					<p>
						6항. 콘텐츠(영화, TV 프로그램 등)의 권리를 보유하고 있는 개인, 단체, 법인(‘권리자 등’) 간의
						계약의 체결 여부 및 개별 계약의 내용, ‘권리자 등’의 사후 요청에 따라 콘텐츠의 이용이 제한되거나
						변경될 수 있습니다. 회사는 ‘권리자 등’의 요청이 있게 되는 경우, 해당 요청에 따라 유료 서비스를
						지원하는 기기의 종류, 회원별 이용 가능 기기 대 수, 동시 재생 회선 수 등을 변경할 수 있습니다.
					</p>
				</div>
				<div class="text_bundle">
					<h3 id="link10">제10조 결제 수단</h3>
					<p>회원이 유료 서비스의 결제를 위하여 이용할 수 있는 수단은 다음 각 호와 같습니다.</p>
					<ul>
						<li>1) 신용카드</li>
						<li>
							2) 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 의한 통신과금서비스
							<div class="text_indent">
								- 서비스 요금이 회사와 제휴한 통신사의 통신요금과 합산하여 청구됩니다.
							</div>
						</li>
						<li>3) 전자지급결제 대행업을 영위하는 제3자를 통한 결제 서비스</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link11">제11조 서비스 지역</h3>
					<p>유료 서비스는 대한민국에서만 이용 가능합니다.</p>
				</div>
				<div class="text_bundle">
					<h3 id="link12">제12조 개인정보의 변경</h3>
					<ul>
						<li>
							① 회원은 이용신청 시 허위의 정보를 제공하여서는 아니 되며, 기재한 사항이 변경되었을 경우에는
							즉시 변경사항을 최신의 정보로 수정하여야 합니다.
						</li>
						<li>
							② 수정하지 않은 정보로 인하여 발생하는 손해는 당해 회원이 전적으로 부담하며, 회사는 이에
							대하여 아무런 책임을 지지 않습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link13">제13조 개인정보의 보호</h3>
					<p>
						회사는 회원 개인정보보호를 위해 관련법령 및 회사가 정하는 "개인정보처리방침"의 규정에 의거하여
						개인정보보호를 위해 노력하고 있으며, "개인정보처리방침"은 본 약관과 동일한 효력을 가집니다.
					</p>
				</div>
				<div class="text_bundle">
					<h3 id="link14">제14조 아이디 및 비밀번호의 관리</h3>
					<ul>
						<li>
							① 아이디 및 비밀번호에 대한 관리책임은 회원에게 있으며, 회원은 어떠한 경우에도 본인의 아이디
							또는 비밀번호를 타인에게 양도하거나 대여할 수 없습니다.
						</li>
						<li>
							② 회사의 귀책사유 없이 아이디 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나
							손해에 대하여는 회원 본인이 그에 대한 책임을 부담합니다.
						</li>
						<li>
							③ 회원은 아이디 또는 비밀번호를 도난당하거나 제3자가 무단으로 이를 사용하고 있음을 인지한
							경우, 이를 즉시 회사에 통보하여야 하고 회사는 이에 대한 신속한 처리를 위하여 최선의 노력을
							다하여야 합니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link15">제15조 이용계약의 종료</h3>
					<ul>
						<li>
							① 회원의 해지
							<ul>
								<li>
									1. 회원이 이용계약을 해지하고자 할 경우에는 본인이 홈페이지 또는 어플리케이션 상에서
									또는 회사가 정한 별도의 이용방법으로 회사에 해지신청을 하여야 하고, 해지신청 시
									이용계약은 자동으로 해지됩니다.
								</li>
								<li>
									2. 이용계약 해지로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며,
									이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 중단, 회수할 수
									있습니다.
								</li>
								<li>
									3. 회원의 의사로 이용계약을 해지한 후, 추후 재이용을 희망할 경우에는 재이용 의사가
									회사에 통지되고, 이에 대한 회사의 승낙이 있는 경우에만 서비스 재이용이 가능합니다.
								</li>
							</ul>
						</li>
						<li>
							② 회사의 해지
							<ul>
								<li>
									1. 회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수
									있습니다.가. 다른 회원의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 대한민국
									법령 또는 공서양속에 위배되는 행위를 한 경우나. 회사가 제공하는 서비스의 원활한
									진행을 방해하는 행위를 하거나 시도한 경우다. 제5조 제2항의 승낙 거절사유가 있음이
									확인된 경우라. 회원이 본 약관에 규정된 금지행위를 한 경우
								</li>
								<li>
									2. 회사는 아이디를 통한 사이트의 최종 접속 후 11개월간 접속 기록이 없는 회원에 대해
									서비스 미 이용 기간(최종 접속 후 1년) 만료 후 개인정보가 파기된다는 사실을 통지하고,
									회원이 통지일로부터 1개월 내에 재접속을 하지 않을 경우 서비스를 해지하며 회원의
									개인정보를 파기합니다. 또한, 회원의 e-mail로 개별 통지가 어려운 경우 서비스 미 이용
									기간 만료 후 개인정보 파기 조치 등에 대한 사전 통지 없이 본 조항에 의거 최종 접속 후
									1년간 접속 기록이 없는 회원에 대하여 서비스를 해지하고 개인정보를 파기합니다.
								</li>
								<li>
									3. 회사가 해지를 하는 경우 회사는 회원에게 이메일, 전화, 기타의 방법을 통하여
									해지사유를 밝혀 해지의사를 통지합니다.
								</li>
								<li>
									4. 본 항에서 정한 바에 따라 이용계약이 종료될 시에는 회사는 회원에게 부가적으로
									제공한 각종 혜택을 중단, 회수할 수 있습니다.
								</li>
								<li>
									5. 본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, 회원의 재이용신청에 대하여
									회사는 이에 대한 승낙을 거절할 수 있습니다.
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>

			<div class="text_bundle_wrap">
				<div class="text_bundle">
					<h2>제3장 서비스의 이용</h2>

					<h3 id="link16">제16조 상품의 구매 결제 및 이용</h3>
					<ul>
						<li>
							① 회사는 스토리버드(STORYBIRD)에서 제공하는 상품 결제 방법으로 상품 대금을 결제할 수 있도록
							제공하며 그 제공 방법의 종류는 서비스 상황에 따라 변경할 수 있습니다.
						</li>
						<li>
							② 상품 대금의 결제와 관련하여 회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과
							불이익은 전적으로 회원이 부담하여야 합니다.
						</li>
						<li>
							③ 상품의 결제방식은 충전한 전용 화폐로 진행되며, 회사에서 지원하는 결제 가능한 결제수단을
							통하여 전용 화폐를 구매하실 수 있습니다.
						</li>
						<li>
							④ 회사는 회원이 상품 구매 계약 체결 내용을 회사에서 정한 방식(유무선사이트, 어플리케이션
							또는 고객센터 등)으로 확인할 수 있도록 제공합니다.
						</li>
						<li>
							⑤ 회원이 유료로 충전한 전용 화폐는 마지막 이용일로부터 5년 경과 시까지 이용하지 않을 경우,
							상법 상사소멸시효에 따라 소멸됩니다.
						</li>
						<li>
							⑥ 회사는 회원이 상품 대금 결제 시 사용한 결제수단에 대해 정당한 사용권한을 가지고 있는지의
							여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래진행을 중지하거나, 확인이 불가한
							해당 거래를 취소할 수 있습니다.
						</li>
						<li>
							⑦ 회사의 정책 및 결제업체의 기준에 따라 회원의 당월 누적 결제액을 바탕으로 서비스 이용이
							제한될 수 있습니다. 해당 기준을 초과한 경우 유료상품의 추가 이용은 불가능할 수 있습니다.
						</li>
						<li>
							⑧ 상품은 라이센스가 유효한 기간 동안 다시 이용할 수 있으며 회원의 훼손, 삭제 등으로 인한
							손실에 대하여 회사는 책임지지 않습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link17">제17조 상품의 구매 방법</h3>
					<ul>
						<li>① 회원은 일정 기간(최소 1달) 동안 멤버쉽 효과를 제공하는 상품을 구매할 수 있습니다.</li>
						<li>
							② 회원은 회사가 지정한 지불수단과 멤버쉽 상품을 통해 원하는 기간만큼 멤버쉽 효과를 구매할 수
							있으며, 멤버쉽 효과는 스토리버드 내에서 모든 유료컨텐츠의 이용이 가능합니다.
						</li>
						<li>
							③ 스토리버드(STORYBIRD)에서 멤버쉽 상품을 해지할 경우 구매한 상품의 멤버쉽 부여 기간에서
							사용할 기간을 뺀 뒤 해당 기간을 가격으로 나누고 수수료까지 제한 금액을 환불할 수 있습니다.
						</li>
						<li>
							④ 전항의 충전 취소는 상품결제 또는 선물 및 이벤트 등 특별한 사유로 인해 부여되는 멤버쉽
							효과에 대해서는 적용되지 않습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link18">제18조 상품 등급과 유료상품 내용 등의 게시</h3>
					<ul>
						<li>
							① 회사는 회원에 대하여 ‘청소년보호법’, ‘게임산업진흥에 관한 법률’ 등에 따른 등급 및 연령
							준수를 위해 이용제한이나 등급별 제한을 할 수 있습니다.
						</li>
						<li>
							② 회사는 다음 사항을 해당 "유료 상품"의 이용화면에 회원이 알기 쉽게 표시 합니다.
							<ul>
								<li>1. "유료상품" 명칭</li>
								<li>2. "유료상품"의 내용, 이용방법, 이용료, 기타 이용 조건</li>
							</ul>
						</li>
						<li>
							③ 회사는 유료 상품을 제공함에 있어 회사의 정책에 따라 회원에게 유료 상품의 이용기한을 정할
							수 있습니다. 유료 상품의 이용기한은 각 유료 상품 세부이용지침에서 정하거나 각 유료 상품
							결제화면 등에 게시하여 회원에게 공지합니다.
						</li>
						<li>
							④ 회원은 유료 상품을 이용하기 전에 각 유료 상품의 세부이용지침에서 공지하는 서비스 이용
							단말기의 사양, 유ㆍ무선 통신망의 품질 등이 해당 서비스의 이용에 적합한지 여부를 사전에
							확인하여야 합니다.
						</li>
						<li>
							⑤ 회사의 귀책사유로 인하여 회원이 유료 상품 이용기간 동안 서비스를 제공받을 수 없게 되는
							경우, 회사는 유료 상품 이용잔여기간 동안 이용할 수 있는 동일ㆍ유사한 콘텐츠를 다시
							제공하거나, 스토리버드(STORYBIRD)에서 이용할 수 있는 재화의 형태로 지급합니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link19">제19조 청약철회, 환불</h3>
					<ul>
						<li>
							① 회원은 ‘전자상거래 등에서의 소비자보호에 관한 법률’에 따라, 구매한 상품에 대해 상품 수령
							후 7일 이내에 청약 철회, 반품 또는 교환을 요청할 수 있으며, 이 경우 회사는 법령에 의한
							청약철회, 반품 등의 절차를 이행 합니다. 반품 또는 교환에 소요되는 비용은 반품에 대한
							귀책사유가 있는 자에게 일반적으로 귀속됩니다. 즉 회원의 단순변심인 경우는 회원이 반품비용을
							부담하며, 상품의 하자로 인한 반품의 경우는 회사가 해당비용을 부담합니다.
						</li>
						<li>
							② 스토리버드(STORYBIRD) 서비스를 통해 거래되는 상품 중 다음 각호의 경우에는 청약을 철회,
							환불 또는 교환을 요청할 수 없습니다.
							<ul>
								<li>
									1. 회원의 책임이 있는 사유로 상품이 멸실되거나 훼손된 경우, 다만, 재화등의 내용을
									확인하기 위하여 포장 등을 훼손한 경우는 제외
								</li>
								<li>2. 회원의 상품 사용 또는 일부 소비로 상품의 가치가 현저히 감소한 경우</li>
								<li>3. 시간이 지나 다시 판매하기 곤란할 정도로 상품의 가치가 현저히 감소한 경우</li>
								<li>4. 디지털 콘텐츠 및 복제가 가능한 재화 등의 포장을 훼손한 경우</li>
								<li>
									5. 구매하는 디지털 상품이 구매 즉시 이용 가능한 상태가 되거나 이용하는 것으로 볼 수
									있는 경우
								</li>
								<li>6. 전자책 구매 후 다운로드를 받은 경우</li>
							</ul>
						</li>
						<li>
							③ 회사는 회원이 제2항에 의하여 청약을 철회, 환불 또는 교환이 제한되지 않는 상품을 구매한
							경우에는 해당 상품이 일부 사용되거나 일부 소비된 경우 그 상품의 일부 사용 또는 일부 소비에
							의하여 회원이 얻은 이익 또는 그 상품의 공급에 든 비용을 회원에게 청구할 수 있습니다.
						</li>
						<li>
							④ 상품의 기능상 중대한 오류로 인하여 해당 상품의 본래 이용목적의 달성이 현저히 곤란하거나
							불가능한 경우 회사는 구매상품에 대하여 회원의 선택에 따라 환불을 하거나 대체상품을 지급할 수
							있고, 회사의 책임이 있는 사유로 과오금이 발생한 경우 과오금을 전액 환급합니다. 이 경우
							회원은 상품의 하자 등의 환불사유를 회사에 제공하여야 합니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link20">제20조 미성년자 이용계약에 관한 특칙</h3>
					<p>
						1항. 회사는 만 19세 이하의 미성년이용자가 유료 서비스를 이용하고자 하는 경우에 부모 등 법정
						대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을
						취소할 수 있다는 내용을 계약체결 전에 고지하는 조치를 취합니다. 다만, 미성년자가 속임수로써
						자기를 성년자로 믿게 하거나 법정대리인의 동의가 있는 것으로 믿게 한 경우에는 취소할 수 없습니다.
					</p>
					<p>
						2항. 전 항과 관련하여 당사자가 미성년자인지 여부는 결제가 진행된 모바일 기기, 결제 실행자의
						정보, 결제수단의 명의자 등을 근거로 하여 종합적으로 판단합니다. 또한 회사는 정당한 취소권의
						행사인지 여부를 확인하기 위하여 미성년자 및 법정대리인임을 증명할 수 있는 서류의 제출을 요청할
						수 있습니다.
					</p>
				</div>
				<div class="text_bundle">
					<h3 id="link21">제21조 서비스 기간과 중지 등</h3>
					<ul>
						<li>① 본 약관에 따른 서비스 기간은 이용계약의 성립 시로부터 이용계약의 해지 시까지 입니다.</li>
						<li>
							② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나 중지할 수 있으며,
							그 내용을 사전에 회원에게 고지하거나, 사전 고지가 어려운 사항일 경우 사후에 고지할 수
							있습니다.
							<ul>
								<li>1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
								<li>2. 서비스 업그레이드 및 사이트 유지보수 등을 위해 필요한 경우</li>
								<li>3. 회원이 회사의 영업활동을 방해하는 경우</li>
								<li>
									4. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이
									있는 경우
								</li>
								<li>
									5. 영상, 음악 등 각종 콘텐츠 등의 원 저작권자 또는 관련 원천권리권자와의 계약 종료
									등 서비스 제공을 위한 원천 데이터 확보가 어려운 경우
								</li>
								<li>6. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</li>
							</ul>
						</li>
						<li>
							③ 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 서비스를 수정 할 수 있으며,
							서비스 수정을 하는 경우에는 수정 후 회사의 서비스 등을 통하여 공지합니다.
						</li>
						<li>
							④ 회사는 다음 각 호의 어느 하나에 해당하는 사유가 발생하는 경우, 서비스 종료 사유 및
							종료일자 등을 30일 전에 우편, SMS, 전자우편, 유ㆍ무선 전화 등 회원이 명확히 인식할 수 있는
							방법으로 회원에게 통지함으로써 서비스의 제공을 중단 또는 종료할 수 있습니다.
							<ul>
								<li>
									1. 사업환경의 변화 또는 기술 발전 등으로 인하여 서비스를 계속 제공하는 것이 곤란한
									경우
								</li>
								<li>
									2. 행정기관의 행정처분이나 법원의 판결, 결정 등으로 인하여 서비스를 계속 제공할 수
									없는 경우
								</li>
								<li>
									3. 전시, 사변, 천재지변 기타 불가항력적 사유로 서비스를 제공하는 것이 곤란한 경우
								</li>
							</ul>
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link22">제22조 서비스 이용료</h3>
					<p>회사는 회사의 내부 정책에 따라 서비스에 대한 이용료(서비스 이용료)를 부과할 수 있습니다.</p>
				</div>
				<div class="text_bundle">
					<h3 id="link23">제23조 정보 및 광고의 제공</h3>
					<ul>
						<li>
							① 회사는 회원의 서비스 이용과 관련하여 필요한 정보를 사이트에 게재하거나 어플리케이션의 푸쉬
							알림, 우편물, 이메일 및 SMS, MMS 등을 통해 제공할 수 있습니다.
						</li>
						<li>
							② 단, 회원이 정보제공을 원치 않는다는 의사를 밝히는 경우 정보제공대상에서 해당 회원을
							제외하며, 정보제공대상에서 제외되어 정보를 제공받지 못해 불이익이 발생할 경우에는 회사는
							이에 대한 책임을 지지 않습니다.
						</li>
						<li>
							③ 회사는 정보와 함께 유무선 사이트, 어플리케이션의 푸쉬 알림 및 게시물을 통해 배너 및 동영상
							형태의 광고를 게재할 수 있고, 이때 회원의 특성에 맞는 맞춤 광고를 제공하기 위해
							‘개인정보처리방침’에 위배되지 않는 범위에서 회원의 개인정보를 활용할 수 있습니다.
						</li>
						<li>
							④ 이때 회사는 정보와 함께 제3자의 광고를 게재할 수 있으나, 회원이 제3자의 광고를 통해
							제3자의 서비스를 이용하는 것과 관련하여 발생하는 문제에 대해서 회사는 고의 또는 과실이 없는
							한 책임을 지지 않습니다.
						</li>
						<li>
							⑤ 제3항의 광고를 어플리케이션의 푸쉬알림에 의하여 전송하고자 하는 경우에는 사전 동의를
							받아서 전송합니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h2>제4장 회원 관리 및 보호</h2>
					<h3 id="link24">제24조 회원 관리</h3>
					<ul>
						<li>
							① 회사는 본 약관의 내용과 관련 법령 및 상거래의 일반원칙을 위반한 회원에 대하여 다음과 같은
							조치를 할 수 있습니다.
							<ul>
								<li>1. 회사가 제공한 혜택의 일부 또는 전부의 회수</li>
								<li>2. 특정 서비스 이용제한</li>
								<li>3. 이용계약의 해지</li>
								<li>4. 손해배상의 청구</li>
							</ul>
						</li>
						<li>
							② 회사가 전항 각 호에 정한 조치를 할 경우 회사는 사전에 회원에게 유선 또는 이메일로
							통보하며, 회원의 연락이 두절되거나 긴급을 요하는 것과 같이 부득이한 경우 선조치 후 사후
							통보할 수 있습니다.
						</li>
						<li>
							③ 회원은 본 조에 의한 회사의 조치에 대하여 항변의 사유가 있는 경우 이에 대하여 항변을 할 수
							있습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link25">제25조 게시물 또는 내용물의 삭제</h3>
					<ul>
						<li>
							① 회사는 회원이 게시하거나 전달하는 모든 게시물이 다음 각 호의 경우에 해당한다고 판단되는
							경우 사전통지 없이 삭제할 수 있습니다.
							<ul>
								<li>
									1. 회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인
									경우
								</li>
								<li>2. 공공질서 및 미풍양속에 위반되는 내용의 게시물 유포 등에 해당하는 경우</li>
								<li>3. 범죄 행위에 결부된다고 인정되는 내용인 경우</li>
								<li>4. 회사 또는 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
								<li>
									5. 본 조 제2항 소정의 세부이용지침을 통하여 회사에서 규정한 게시기간을 초과한 경우
								</li>
								<li>6. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
								<li>7. 기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우</li>
							</ul>
						</li>
						<li>
							② 회사는 게시물에 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에
							따라 각종 게시물을 등록하거나 삭제하여야 합니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link26">제26조 게시물의 저작권</h3>
					<ul>
						<li>
							① 회사는 회원이 서비스 내에 게시한 게시물(회원간 전달 포함)을 서비스 내에 게시할 수 있는
							권리를 갖습니다.
						</li>
						<li>
							② 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램 저작권 등을 침해하더라도
							회사에게 고의 또는 과실이 없는 한 이에 대한 민, 형사상의 책임을 부담하지 않습니다.
						</li>
						<li>
							③ 회사는 회원이 이용계약을 해지하거나 적법한 사유로 이용계약이 해지 된 경우 해당 회원이
							게시하였던 게시물을 삭제할 수 있습니다. 또한 회사는 회원의 게시물이 저작권을 침해하는 것으로
							추정되는 경우에 이를 본 약관이 정한 방법에 의하여 회원에게 통지하고 삭제 할 수 있습니다.
						</li>
						<li>④ 회사가 작성한 저작물에 대한 저작권은 회사에 귀속합니다.</li>
						<li>
							⑤ 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를
							영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계
							법령의 적용을 받습니다.
						</li>
					</ul>
				</div>
				<div class="text_bundle">
					<h3 id="link27">제27조 금지행위</h3>
					<ul>
						<li>
							① 회원은 본인의 아이디 및 비밀번호를 타인에게 양도하거나 누설 또는 이용하게 해서는 아니 되고
							본인의 관리책임 소홀로 발생하는 모든 문제에 대해서 책임을 져야 하며, 아이디 및 비밀번호가
							유출되었을 때는 그 사실을 즉시 회사에 알려야 할 의무가 있습니다. 또한 회원 본인의 고유번호
							및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고
							회사의 안내가 있는 경우 그에 따라야 합니다.
						</li>
						<li>
							② 회원이 본 서비스를 통하여 게시, 게재, 전자메일, 기타 제반 전송 한 모든 내용은 회원의
							소유이며, 이에 대하여 회원이 책임을 지게 됩니다.
						</li>
						<li>
							③ 회원은 다음 각 호의 행위를 하여서는 아니 됩니다.
							<ul>
								<li>
									1. 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나
									상업적으로 이용하는 행위
								</li>
								<li>2. 타인의 명예를 손상시키거나 불이익을 주는 행위.</li>
								<li>3. 음란물을 게재, 공개 하거나 음란사이트를 연결(링크)하는 행위</li>
								<li>4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위.</li>
								<li>
									5. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게
									유포하는 행위
								</li>
								<li>
									6. 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터
									바이러스 감염 자료를 등록 또는 유포하는 행위
								</li>
								<li>
									7. 서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및
									수신자의 의사에 반하여 광고성 정보를 전송하는 행위
								</li>
								<li>
									8. 회사에서 공식적으로 인정한 경우를 제외한 서비스를 이용하여 상품을 판매하는 영업
									활동 및 해킹, 광고를 통한 수익, 음란사이트를 통한 상업행위, 상용소프트웨어 불법배포
									행위.
								</li>
								<li>
									9. 법률에 의하거나 계약상 또는 위임에 의하여 전송할 수 있는 권리가 없는 내용을 게시,
									게재, 전자메일 또는 여하한의 방법으로 전송하거나 공개하는 행위.
								</li>
								<li>
									10. 타인을 "스톡(stalk)"하거나 달리 괴롭히는 행위 또는 다른 회원에 대한 개인정보를
									수집 또는 저장하는 행위
								</li>
								<li>11. 판매에 부적합한 상품을 등록하는 행위</li>
								<li>
									12. 회사가 제공한 서비스 이용방법이 아닌 비정상적인 방법으로 본 서비스를 이용하거나
									시스템에 접근하는 행위
								</li>
								<li>
									13. 허위 사실을 기재하거나 다른 회원의 ID 및 비밀번호, 카드정보, 계좌정보 등을
									도용하여 서비스 이용 신청하거나 변경하는 행위
								</li>
								<li>
									14. 회사의 직원이나 운영자를 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나
									메일을 발송하는 행위
								</li>
								<li>15. 타인과의 관계를 허위로 명시하거나 타인으로 가장하는 행위</li>
								<li>
									16. 자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의
									정보를 유통시키는 행위
								</li>
								<li>17. 상품의 구입과 철회를 반복하여 서비스 운영에 지장을 주는 행위</li>
								<li>
									18. 본 서비스의 해지 및 재가입을 반복적으로 행함으로써 회사가 제공하는 할인쿠폰,
									이벤트 혜택 등 경제상의 이익을 불 • 편법으로 수취하는 행위
								</li>
								<li>19. 콘텐츠의 DRM(Digital Rights Management)을 무력화하는 일체의 행위</li>
								<li>20. 동일한 아이디로 5대 이상의 모바일 단말기에서 전자책을 이용하는 경우</li>
								<li>
									21. 자신의 아이디 및 전차책, 이용권, 쿠폰, 코인 등 서비스 요소의 전부 또는 일부를
									타인에게 판매, 대여, 양도하는 행위 및 이러한 행위를 광고하는 행위
								</li>
							</ul>
						</li>
						<li>
							④ 회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는
							사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 아니 됩니다.
						</li>
						<li>
							⑤ 회원의 본 조 위반이 있을 경우 회사는 회원의 계정을 중지하거나 해지할 수 있으며, 현재 또는
							장래의 모든 또는 일부의 서비스 이용을 거절 할 수 있습니다. 또한 본 조의 위반으로 인해 발생된
							회원의 관계기관에 의한 구속 등 법적 조치 등에 관해서는 회사가 책임을 지지 않으며, 회원은
							이와 같은 행위와 관련하여 회사에 손해를 끼쳤을 경우 회사에 대하여 손해배상 의무를 집니다.
						</li>
					</ul>
				</div>
			</div>
			<div class="text_bundle_wrap">
				<h2>제5장 기타</h2>
				<h3 id="link28">제28조 약관 외 준칙 및 관련 법령과의 관계</h3>
				<p>
					본 약관에 명시되지 않은 사항에 대해서는 전기통신사업법 및 전자거래기본법, 콘텐츠산업진흥법,
					전자상거래 등에서의 소비자보호에 관한 법률, 저작권법 등 관계법령 및 회사가 정한 서비스의
					세부이용지침 등의 규정에 의합니다.
				</p>
			</div>
			<div class="text_bundle">
				<h3 id="link29">제29조 면책 사항</h3>
				<ul>
					<li>
						① 회사는 다음 각 호에 해당하는 사유로 인하여 회원 또는 제3자에게 발생한 손해에 대하여는 그
						책임을 지지 아니합니다.
						<ul>
							<li>1. 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할 수 없는 경우</li>
							<li>
								2. 회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 하는 등 타인의 부정사용을
								방치한 경우
							</li>
							<li>
								3. 회원이 제3자의 아이디, 비밀번호, 휴대폰, 계좌, 신용카드 번호 등 개인정보를 도용하여
								제3자에게 손해를 발생시킨 경우
							</li>
							<li>4. 회사의 관리 영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우</li>
							<li>5. 기타 회사의 귀책 사유가 없는 통신서비스 등의 장애로 인한 경우</li>
							<li>
								6. 회원이 본 서비스를 이용하여 기대하는 이익을 상실하거나, 그 밖에 본 서비스를 통하여
								얻은 자료로 인한 손해를 입은 경우
							</li>
							<li>7. 회원 상호간 또는 회원과 제3자 상호간에 본 서비스를 매개로 발생한 분쟁의 경우</li>
							<li>
								8. 회원이 제공하는 상품 또는 정보 등을 통해 표현하거나 드러나는 회원의 견해에 따른 경우
							</li>
							<li>
								9. 본 서비스 화면에서 링크, 배너 등을 통하여 연결된 회사와 회원간에 이루어진 거래의 경우
							</li>
						</ul>
					</li>
					<li>
						② 회사는 회원이 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 회사에게 고의 또는
						과실이 없는 한 책임을 지지 않습니다.
					</li>
				</ul>
			</div>
			<div class="text_bundle">
				<h3 id="link30">제30조 관할 법원</h3>
				<ul>
					<li>
						① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해
						성실히 협의합니다.
					</li>
					<li>
						② 본 조 제1항의 협의에서도 분쟁이 해결되지 않아 소송이 제기되는 경우에는 민사소송법에 따라
						관할법원을 정합니다.
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
